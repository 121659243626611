// .trans{
//     z-index:5px;
// }
#google_translate_element_sign {
     margin-top: -24px;
height: 30px;
margin-left: 80%;
}

.account_name_field{
    margin-top: -34px;
    margin-left: 192px;
    width: 248px;
}

// a.hover {
//     color: red;
//     text-decoration: none;
// }

// a.hover:hover {
//     text-decoration: underline;
// }